<template>
  <div class="spinner" :class="{ visible: isLoaded }">
    <AppIcon name="spinner" size="12px" is-img-tag />
    <AppText size="15px" class="d-flex">
      {{ title }}
    </AppText>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'AppSpinner',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isLoaded = ref(false);

    setTimeout(() => {
      isLoaded.value = true;
    }, 0);

    return {
      isLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  @include flex-center;
  opacity: 0;

  transition: all 0.2s;

  &.visible {
    opacity: 1;
  }

  :deep(.icon) {
    margin-right: 8px;
  }
}
</style>
