<template>
  <div class="currency-select">
    <UidPlan
      class="user__info"
      :plan="paymentData.merchant.subscription.plan"
      :uid="paymentData.merchant.uid"
    />

    <AppText variant="div" size="16px" class="font-medium amount">
      <i18n-t keypath="payment.label.pay">
        <template #amount>
          {{ `${baseCurrencySymbol}${baseCurrencyAmount}` }}
        </template>
      </i18n-t>
    </AppText>
    <div
      class="coins opacity"
      :class="{
        'is-visible': !isLoading,
      }"
    >
      <template v-for="coinInfo in coins" :key="coinInfo.coin">
        <AppTooltip
          v-if="coinInfo.isPrimary"
          :is-enabled="!isLoading && (coinInfo.isDisabledByAdmin || !coinInfo.enabled)"
          text-align="left"
        >
          <template #text>
            {{ coinInfo.isDisabledByAdmin ? t('payment.label.coinIsdisabledGlobally') : t('payment.label.coinIsDisabledByMerchant') }}
          </template>

          <CoinItem
            :key="coinInfo.icon"
            :coin-info="coinInfo"
            :is-loading="isLoading"
            :is-select-loading="isSelectLoading"
            :is-disabled="coinInfo.isDisabledByAdmin || !coinInfo.enabled"
            @click="onCoinSelect(coinInfo.value)"
          />
        </AppTooltip>
      </template>
      <CurrencyDropdown
        v-if="!isLoading"
        active-icon="coins-group-active"
        disabled-icon="coins-group-disabled"
        :title="t('common.thirdPartyTokens')"
        block-height="250px"
        :coins="coins.filter(coin => !coin.isPrimary)"
        @click="value => onCoinSelect(value)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import UidPlan from '@/components/UidPlan.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import { addThousandsSeparators } from '@/utils/numberFormatter';
import { currencyList } from '@/common/data';

import CoinItem from './CoinItem.vue';
import CurrencyDropdown from './CurrencyDropdown.vue';

export default {
  name: 'CurrencySelect',
  components: {
    CoinItem,
    UidPlan,
    AppTooltip,
    CurrencyDropdown,
  },
  props: {
    paymentData: {
      type: Object,
      default: () => {},
    },
    isSelectLoading: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    coins: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['coin-select'],
  setup(props, { emit }) {
    const onCoinSelect = (coin) => {
      emit('coin-select', coin);
    };

    const baseCurrencyAmount = computed(() => addThousandsSeparators({
      val: props.paymentData?.baseAmount,
      separator: props.paymentData?.merchant?.settings?.numberFormat,
      baseCurrency: props.paymentData?.baseCurrency,
    }));

    const baseCurrencySymbol = computed(() => {
      const coin = currencyList.find(({ short }) => short === props.paymentData?.baseCurrency);
      return coin ? coin.symbol : '';
    });

    const isPlanIconVisible = computed(() => ['business', 'black'].includes(props.paymentData.merchant?.subscription?.plan?.toLowerCase()));

    const avatar = computed(() => props.paymentData?.merchant?.profilePicture?.cropUrl);

    return {
      baseCurrencyAmount,
      baseCurrencySymbol,

      onCoinSelect,
      isPlanIconVisible,
      avatar,
    };
  },
};
</script>

<style lang="scss" scoped>
.user__info, .amount {
  padding: 20px 40px 0 40px;
}

.coins {
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;

  :deep(.tooltip) {
    display: block;
  }
}
</style>
