<template>
  <BlockLoader :loading="isLoading" :has-shadow="false">
    <div
      class="item d-flex align-items-center justify-content-between"
      :class="{
        'is-disabled': isDisabled,
        'is-persistent': isPersistent,
        'is-loading': isLoading,
        'is-select-loading': isSelectLoading,
      }"
      :tabindex="isDisabled || !isClickable ? '-1' : '0'"
      @click="isClickable ? onClick() : () => {}"
      @keypress.space.stop="onPressSpace"
    >
      <div class="d-flex align-items-center w-100">
        <slot name="title-icon">
          <CoinIcon :icon="coinInfo.icon" :icon-disabled="coinInfo.iconDisabled" :size="iconSize" :is-disabled="isDisabled" />
        </slot>
        <AppText
          size="14px"
          class="item-title font-medium"
          ml="17px"
        >
          <slot name="name">
            {{ coinInfo?.name }}
          </slot>
        </AppText>

        <FSpacer />

        <slot name="append" />
      </div>
      <div>
        <LoadingIcon v-if="isLoading" />
        <div
          v-else
          class="arrow-icon flex-center"
          style="width: 30px; height: 30px;"
        >
          <AppIcon
            v-if="!isDisabled || isPersistent"
            :key="isDisabled"
            name="arrow-bracket"
            class="rotate-90"
            size="18px"
            is-img-tag
          />
        </div>
      </div>
    </div>
  </BlockLoader>
</template>

<script>
import { computed, ref, watch } from 'vue';

import CoinIcon from '@/components/Icon/CoinIcon.vue';
import BlockLoader from '@/components/Loaders/BlockLoader.vue';

import LoadingIcon from './LoadingIcon.vue';

export default {
  components: {
    CoinIcon,
    BlockLoader,
    LoadingIcon,
  },
  props: {
    coinInfo: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isPersistent: {
      type: Boolean,
      default: false,
    },
    isSelectLoading: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: String,
      default: '36px',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const isLoading = ref(false);

    const onClick = () => {
      if (!props.isDisabled && isLoading.value === false && props.isSelectLoading === false) {
        isLoading.value = true;
        emit('click');
      }
    };

    const onPressSpace = (event) => {
      event.preventDefault();
      onClick();
    };

    watch(computed(() => props.isSelectLoading), (val) => {
      if (!val) {
        isLoading.value = false;
      }
    });

    return {
      onClick,
      onPressSpace,

      isLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.item{
  padding: 0 20px 0 20px;
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--color-white);
  transition: background 0.2s;
  position: relative;

  &:hover {
    cursor: pointer;
    background: var(--color-F7F7F7);
  }
  .arrow-icon {
    opacity: 0.3;
    filter: grayscale(1);
  }

  .item-title, .arrow-icon {
    @include transition-base(opacity)
  }

  &:not(.is-disabled) {
    @include focus-visible;
  }
}

.item.is-disabled {
  .item-title  {
    opacity: 0.2;
    filter: grayscale(1);
  }
  &:hover {
    background: initial;
    cursor: not-allowed;
  }
}

.item.is-persistent {
  &:hover {
    cursor: pointer !important;
  }
}

.is-loading {
  &:hover {
    cursor: wait;
    background: transparent;
  }
}

.is-select-loading {
  &:hover {
    cursor: initial;
    background: transparent;
    .item {
      background: transparent !important;
    }
  }
}
</style>
