export const decryptString = (encoded) => {
  const SECREK_KEY = process.env.VUE_APP_SECRET_KEY;
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));

  /* eslint-disable-next-line */
  const applySecretToChar = (code) => textToChars(SECREK_KEY).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySecretToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};
