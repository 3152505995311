<template>
  <div class="payment-inner">
    <Transition name="show">
      <CurrencySelect
        v-if="['REACTIVATION', 'NEW'].includes(paymentStatus)"
        :payment-data="paymentInfo"
        :coins="merchantsCoins"
        :is-select-loading="isSelectLoading"
        :is-loading="isLoading"
        @coin-select="onCoinSelect"
      />
    </Transition>
    <TransitionGroup name="show">
      <PaymentProgress
        v-if="['WAITING', 'UNDERPAID'].includes(paymentStatus)"
        :payment-data="paymentInfo"
        :payment-status="paymentStatus"
        :exchange-currency="exchangeCurrency"
        :selected-coin="selectedCoin"
      />
      <PaymentStatus
        v-else
        :payment-data="paymentInfo"
        :payment-status="paymentStatus"
        :max-confirmations="confirmations"
        :socket-data="socketData"

        :selected-coin="selectedCoin"
        :exchange-currency="exchangeCurrency"

        @reactivate="onReactivate"
      />
    </TransitionGroup>
  </div>
</template>

<script setup>
import {
  computed, reactive, ref, watch,
} from 'vue';

import { useRoute } from '@/router';
import { paymentState } from '@/layouts/PaymentLayout/paymentState';
import { useSocket } from '@/composables/useSocket';
import { fullCoinsArray } from '@/common/data';
import { round } from '@/utils/functions';

import { useErrorProcessing } from './useErrorProcessing';
import {
  getCoinsAvailability,
  getConfirmations,
  getPaymentById,
  invoicePayment,
  linkAccessed,
  reactivatePayment,
} from './api';
import CurrencySelect from './CurrencySelect';
import PaymentProgress from './PaymentProgress';
import PaymentStatus from './PaymentStatus';

const emit = defineEmits(['load']);

// DATA SECTION
const { params } = useRoute();
const paymentUUID = params.id;

const paymentInfo = reactive({});

const paymentStatus = ref('');
const socketData = reactive({});
const isLoading = ref(true);

const confirmations = ref(null);
const merchantsCoins = ref(fullCoinsArray.filter((coin) => coin.isPrimary));

const selectedCoin = computed(() => fullCoinsArray.find((coin) => coin.value === paymentInfo?.exchangeCurrency));

const exchangeCurrency = computed(() => {
  if (selectedCoin.value?.value === 'ETH_ETH') {
    return selectedCoin.value?.shortValue;
  }
  return selectedCoin.value?.isPrimary ? selectedCoin.value?.value : selectedCoin.value?.name;
});

const apiConfirmations = computed(() => {
  const cnf = [];

  if (paymentInfo.transactions) {
    paymentInfo.transactions.forEach((t) => {
      cnf.push(t.confirmations <= confirmations.value ? t.confirmations : confirmations.value);
    });
  } else {
    cnf.push(0);
  }

  return cnf;
});

const { setAvatar, disableAvatar, isLoaderShow } = paymentState();
isLoaderShow.value = false;

// MAIN SECTION
const processPayment = async (data) => {
  Object.assign(paymentInfo, data);
  paymentInfo.missingAmount = data?.difference;
  paymentInfo.amount = data.expectedAmount;
  paymentStatus.value = data?.status || 'UNAVAILABLE';

  // processFiatFee(data);
  const isThirdPartyToken = !selectedCoin.value?.isPrimary || selectedCoin.value?.shortValue === 'ETH';
  paymentInfo.currencyFees = data.networkFeesFiat;

  if (data.forwarderFeesFiat && isThirdPartyToken) {
    paymentInfo.currencyFees = round(Number(data.networkFeesFiat) + Number(data.forwarderFeesFiat));
  }

  if (paymentStatus.value !== 'NEW') {
    const { confirmations: c } = await getConfirmations(data.exchangeCurrency);
    confirmations.value = c;
  }

  if (['NEW', 'EXPIRED', 'REACTIVATION'].includes(paymentStatus.value)) {
    if (paymentStatus.value === 'NEW') {
      setAvatar(paymentInfo.merchant?.profilePicture?.cropUrl);
    }
    merchantsCoins.value = await getCoinsAvailability(data.merchantId);
    isLoading.value = false;
  } else {
    disableAvatar();
  }
};

const getPaymentData = async ({ isFreshResponse } = { isFreshResponse: false }) => {
  isLoading.value = true;
  const { data, isSuccess } = await getPaymentById(paymentUUID, isFreshResponse);
  disableAvatar();

  if (isSuccess) {
    processPayment(data);
    openSocketConnection();
  } else {
    paymentStatus.value = 'UNAVAILABLE';
    disableAvatar();
  }
};

const isSelectLoading = ref(false);
const onCoinSelect = async (coin) => {
  isSelectLoading.value = true;

  if (paymentStatus.value === 'NEW') {
    const { data, isSuccess } = await invoicePayment({ uuid: paymentUUID, coin });
    if (isSuccess) {
      processPayment(data);
      openSocketConnection();
    }
  }

  if (paymentStatus.value === 'REACTIVATION') {
    const { data, isSuccess } = await reactivatePayment({ uuid: paymentUUID, coin });
    if (isSuccess) {
      processPayment(data);
      openSocketConnection();
    }
  }

  await new Promise((resolve) => { setTimeout(resolve, 0); });
  isSelectLoading.value = false;
};

socketData.paymentsConfirmations = apiConfirmations.value;

watch(apiConfirmations, () => {
  socketData.paymentsConfirmations = apiConfirmations.value;
});

const openSocketConnection = () => {
  // eslint-disable-next-line
  const paymentId = paymentInfo._id;
  const { addSocketListener, socket } = useSocket();
  socket.on('connect', () => {
    socket.emit('room', paymentId);
  });
  addSocketListener(`${paymentId}-status-update`, onGetMessage);
};

const onGetMessage = (info) => {
  if (paymentUUID === info.uuid) {
    if (info.status && info.status !== 'WAITING') {
      paymentStatus.value = info.status;
    }
    if (info.status !== 'NEW') {
      disableAvatar();
    }
    if (info.eventName === 'CONFIRMING') {
      paymentInfo.status = 'CONFIRMING';
      paymentStatus.value = 'CONFIRMING';
    }

    processPayment(info);
  }
};

const { addSocketListener } = useSocket();
addSocketListener('subscription-payment-status-update', onGetMessage);

const onReactivate = async () => {
  await getPaymentData();

  paymentStatus.value = 'REACTIVATION';
  setAvatar(paymentInfo.merchant?.profilePicture?.cropUrl);

  isLoading.value = false;
};

// CHECK IF CURRENT PAYMENT IS PAYMENT FROM SWITCH OR 3RD-PARTY SERVICE
const onGetPayment = async () => {
  getPaymentData();
  emit('load');
};
const { data } = useErrorProcessing();
if (data?.status === 'API_PAYMENT_ERROR') {
  paymentStatus.value = 'API_PAYMENT_ERROR';

  Object.assign(paymentInfo, data);
  emit('load');
}

if (data?.status !== 'API_PAYMENT_ERROR') {
  onGetPayment();
  linkAccessed(paymentUUID);
}
</script>

<style lang="scss" scoped>
.payment-inner {
  position: relative;

  @include mq('mobile') {
    padding-bottom: 65px;
  }
}
</style>
