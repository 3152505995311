<template>
  <DropdownBlock type="blank" :height="blockHeight" class="currency-dropdown" :has-icon="false">
    <template #header>
      <CoinItem
        :is-clickable="false"
        :is-disabled="!atLeastOneCoinActive"
        class="currency-title-block"
        is-persistent
      >
        <template #title-icon>
          <CoinIcon
            class="icon-wrapper"
            :icon="activeIcon"
            :icon-disabled="disabledIcon"
            size="36px"
            :is-disabled="!atLeastOneCoinActive"
          />
        </template>
        <template #name>
          <span class="font-medium coin-title">
            {{ title }}
          </span>
        </template>
      </CoinItem>
    </template>
    <template #default>
      <div class="coins-group">
        <slot>
          <div v-for="coin in coins" :key="coin.value" class="item-wrapper">
            <AppTooltip
              :is-enabled="coin.isDisabledByAdmin === true || !coin.enabled"
              text-align="left"
            >
              <template #text>
                {{ coin.isDisabledByAdmin === true ? t('payment.label.tokenIsdisabledGlobally') : t('payment.label.tokenIsDisabledByMerchant') }}
              </template>

              <CoinItem
                :coin-info="coin"
                :is-disabled="coin.isDisabledByAdmin === true || !coin.enabled"
                icon-size="24px"
                @click="$emit('click', coin.value)"
              >
                <template #name>
                  {{ coin.name }}
                </template>
                <template #append>
                  <div class="flex-center">
                    <AppIcon
                      :name="`${coin.familyIcon}-flat`"
                      size="18px"
                      :opacity="coin.enabled ? 1 : 0.2"
                      is-img-tag
                      style="margin-right: 8px;"
                    />
                    <AppText variant="div" :color="coin.enabled ? 'var(--color-black-04)' : 'var(--color-black-02)'" :line-height="1.5" style="width: 48px">
                      {{ coin.family }}
                    </AppText>
                  </div>
                </template>
              </CoinItem>
            </AppTooltip>
          </div>
        </slot>
      </div>
    </template>
  </DropdownBlock>
</template>

<script>
import { defineComponent, computed } from 'vue';

import DropdownBlock from '@/components/Containers/DropdownBlock.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import CoinIcon from '@/components/Icon/CoinIcon.vue';

import { fullCoinsArray } from '@/common/data';

import CoinItem from './CoinItem.vue';

export default defineComponent({
  components: {
    CoinItem,
    CoinIcon,
    AppTooltip,
    DropdownBlock,
  },
  props: {
    activeIcon: {
      type: String,
      default: '',
    },
    disabledIcon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Title',
    },
    blockHeight: {
      type: String,
      default: '128px',
    },
    coins: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],
  setup(props) {
    const atLeastOneCoinActive = computed(() => props.coins.some((coin) => !coin.isDisabledByAdmin && coin.enabled));

    return { fullCoinsArray, atLeastOneCoinActive };
  },
});
</script>

<style scoped lang="scss">
.icon-wrapper {
  display: flex;
  width: 36px;
  height: 20px;
  position: relative;
}

.currency-title-block {
  :deep(.item) {
    margin-top: 0;
  }
  :deep(.arrow-icon) {
    @include transition-base(transform)
  }
}

.currency-dropdown {
  background-color: #F7F7F7 !important;
  border-radius: 8px;
  margin: 0px;

  @include transition-base(margin);

  :deep(.dropdown-block-body) {
    padding: 0px !important;
  }
  .item-wrapper {
    padding: 0 10px;
  }
  &.is-wrapper-open {
    background-color: #F7F7F7 !important;
    margin: 10px 0 8px;
    border-radius: 8px;
    :deep(.item) {
      margin-top: 0;
      background-color: #F7F7F7 !important;
    }
    :deep(.arrow-icon) {
      transform: rotate(90deg);
    }
    .coins-group {
      :deep(.arrow-icon) {
        transform: initial;
      }
    }
  }
}

.coins-group {
  padding-top: 2px;
  :deep(.item-title) {
    font-size: 13px;
  }
  :deep(.block-loader) {
    .item {
      height: 40px;
      padding: 0 10px;
      background-color: #F7F7F7 !important;
      &:hover {
        background-color: var(--color-black-003) !important;
      }
      &.is-disabled {
        &:hover {
          background-color: initial !important;
        }
      }
    }
  }
}

</style>
